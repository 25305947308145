import React,{ useState,useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import {
    loadingToggleAction,
    loginAction,
    loginFailedAction
} from '../../store/actions/AuthActions';
// image
//import logo from "../../images/logo-text.png";
//import loginbg from "../../images/login-bg-1.jpg";
import loginbg from "../../images/login-bg.png";
//import loginbg from "../../images/login-bg-1.jpg";

import loginBanner from "../../images/logo.png";

import { useAuth } from "../../providers/use-auth";

import {
  Spinner,
} from "react-bootstrap";

function Login (props) {
  const [email, setEmail] = useState('');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    //firebase
    const auth = useAuth();


    useEffect(async () => {
      dispatch(loginFailedAction(null));
  }, [dispatch]);

    const onLogin = async (e) => {
    //function onLogin(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
			return ;
		}
		dispatch(loadingToggleAction(true));	

    let response = await auth.signin(email,password);
    if(response.hasOwnProperty("message")){

      console.log("sign in error");
      console.log(response.message);
      dispatch(loadingToggleAction(true));	
      dispatch(loginFailedAction(response.message));
      return;
    }
    
    if(response.hasOwnProperty("user")){
      console.log(response.user);
      //dispatch(signIn(response.user,"email"));
      const idToken = await response.user.getIdToken(true);
      dispatch(loginAction(idToken,response.user.displayName, response.user.email, props.history));
      return;  
    }

        //dispatch(loginAction(email, password, props.history));
    }

  return (
   
    <div className="" style={{background:"#17556D"}}>
		<div className="login-main-page" style={{backgroundImage:"url("+ loginbg +")"}}>
           
                
    <div className="row justify-content-center align-items-center p-2">
               <div className="col-md-4">
                 
                              <div className="card p-2" style={{borderRadius: "8px"}}>

                              <img class="card-img-top" src={loginBanner} alt=""/>

                              <div class="card-body">
                                <div className="mb-4">
                                    <h3 className="dz-title mb-1">Admin Login</h3>
                                </div>
                                
                                <form onSubmit={onLogin}>
                                    <div className="form-group">
                                        <label className="mb-2 ">
                                          <strong>Mail Id</strong>
                                        </label>
                                        <input type="email" className="form-control"
											value={email}
                                           onChange={(e) => setEmail(e.target.value)}
										   placeholder="Type Your Email Address"
                                        />
                                      
                                      </div>
                                    <div className="form-group">
                                        <label className="mb-2 d-flex flex-wrap align-items-center"><strong className="mr-auto">Password</strong> <Link to="/forgot-password">Forgot Password?</Link> </label>
                                        <input
                                          type="password"
                                          className="form-control"
                                          value={password}
										  placeholder="Type Your Password"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                        />
                                        {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                    </div>

                                    <input
                                          type="hidden"
                                          className="custom-control-input"
                                          id="basic_checkbox_1"
                                          value="true"
                                        />

                                  {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                    <div className="form-group">
                                      <div className="custom-control custom-checkbox ml-1 ">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="basic_checkbox_1"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="basic_checkbox_1"
                                        >
                                          Remember my preference
                                        </label>
                                      </div>
                                    </div>
                                  </div> */}

                                  <div className="text-center mt-4">
                                  {props.showLoading ?<Spinner animation="border" variant="primary" /> :<button
                                      type="submit"
                                      className="btn btn-primary btn-block"
                                    >
                                      Sign In
                                    </button>}
                                  </div>

                                  {props.errorMessage && (
                                    <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2 text-danger'>
                                        {props.errorMessage}
                                    </div>
                                )}
                                {props.successMessage && (
                                    <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                                        {props.successMessage}
                                    </div>
                                )}

                                </form>
                               
                                </div>


                             
                        </div>
                      </div>
                    </div>
                
  
            
        </div>
        </div>
      
	);
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);
