import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import axiosInstance from '../../services/AxiosInstance';
import swal from "sweetalert";
import { format } from "date-fns";

import { Table, Pagination, Badge,Dropdown,Spinner ,Button} from "react-bootstrap";

import { CSVLink } from "react-csv";

const AgentDetails = ({ match, history }) => {

   const dispatch = useDispatch();
   const [agent, setAgent] = useState(null);
   const [members, setMembers] = useState([]);
   const [xlsData, setXlsData] = useState([]);
   const [agents, setAgents] = useState([]);

   const [counter, setCounter] = useState("");
   const [allotCounterLoading, setAllotCounterLoading] = useState(false);

   const [activeToggle, setActiveToggle] = useState("members");
   const [sendMessage, setSendMessage] = useState(false);

   const [replay, setReplay] = useState(false);

   const options = {
      settings: {
         overlayColor: "#000000",
      },
   };


   useEffect(() => {
   
      // loadAgentDetails(match.params.id);
      // loadMembers(match.params.id);
      
   }, [dispatch]);


   

   useEffect(() => {
   
      loadAgentDetails(match.params.id);
      loadMembers(match.params.id);
      loadAgents(match.params.id);
      
   }, [match]);

   const loadAgents = async (agentId) => {

      
      const { data } = await axiosInstance.post(
         "admin/agents",
         {
            "sub_agent":true,
            "agent_id":agentId
         }
       );
 
       if(data.status){
         setAgents(data.agents);
       }
       
   }

    const loadAgentDetails = async (userId) => {
      const { data } = await axiosInstance.get(
         "admin/user/"+userId
       );
 
       if(data.status){
         setAgent(data.user);
         document.getElementById("nav_title").innerHTML = data.user.name? data.user.name :data.user.email;
       }
    }

    const loadMembers = async (userId) => {
      const { data } = await axiosInstance.post(
         "admin/agent-members",
         {
            agent_id:userId
         }
       );
 
       if(data.status){
         setMembers(data.members);
       }
    }

    const rewardCounter = async (e) => {
      e.preventDefault();
      setAllotCounterLoading(true);

      const { data } = await axiosInstance.post(
         "admin/reward-counter",
         {
            user_id:match.params.id,
            counter:counter
         }
       );
 
       if(data.status){
         swal('Reward Counter', data.message, "success");
         setCounter("");
         loadAgentDetails(match.params.id);
         //loadMembers(match.params.id);
       }else{
         swal('Reward Counter', data.message, "error");
       }

      setAllotCounterLoading(false);
    }
    

    function inviteStatus(status){

      if(status == "sent"){
         return "text-warning";
      }else if(status == "accepted"){
         return "text-success";
      }else{
         return "text-danger";
      }

    }


    const memberAddress = (member)=>{

      var address = "";

      if(member.address){
         if(member.address.addressLine1){
         
            address = (member.address.addressLine1??"")+", "+
            (member.address.addressLine2??"")+", "+
            (member.address.city??"")+", "+
            (member.address.state??"")+", "+
            (member.address.country??"")+" - "+
            (member.address.zip??"");
         
         }else{
            address = "Not Available";
         }
      }else{
         address = "Not Available";
      }


      return address;

    }

    const initXlsData = async () => {

      var dummy = [];
      members.map((data,i)=>{

         var address = "";
         if(data.address){
            if(data.address.addressLine1){
            
               address = (data.address.addressLine1??"")+", "+
               (data.address.addressLine2??"")+", "+
               (data.address.city??"")+", "+
               (data.address.state??"")+", "+
               (data.address.country??"")+" - "+
               (data.address.zip??"");
            
            }else{
               address = "Not Available";
            }
         }
      
         dummy.push({
            sr_no:i+1,
            id:data.id,
            name:data.name,
            email:data.email,
            department:data.department,
            designation:data.designation,
            card_id:data.cardId,
            address:address,
            joined:format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
         });
      });


      setXlsData(dummy);


   }
   
   
   const changeUserStatus = async (userId,status) => {

      const { data } = await axiosInstance.post(
         "admin/user-status",
         {
            user_id:userId,
            status:status
         }
      );

      if(data.status){
         swal('User', data.message, "success");

         var dummy = [...members];
         
         const index = dummy.findIndex(
            (x) => x.id === userId
         );

         dummy[index].status = status;
         console.log("user index - "+index);

         setMembers(dummy);
      }else{
         swal('User', data.message, "error");
      }

   }


   const changeAgentStatus = async (userId,status) => {

      const { data } = await axiosInstance.post(
         "admin/user-status",
         {
            user_id:userId,
            status:status
         }
      );

      if(data.status){
         swal('Agent', data.message, "success");

         var dummy = [...agents];
         
         const index = dummy.findIndex(
            (x) => x.id === userId
         );

         dummy[index].status = status;
         console.log("user index - "+index);

         setAgents(dummy);
      }else{
         swal('Agent', data.message, "error");
      }

   }



   return (
      <Fragment>
         
         <div className="row">
            <div className="col-lg-12">
            {agent == null ? <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>:
            
            (
               <>
               
               <div className="row">
            <div className="col-lg-12">
               <div className="profile card card-body px-3 pt-3 pb-0">
                  <div className="profile-head">
                     <div className="photo-content">
                       
                     </div>
                     <div className="profile-info">
                        <div className="profile-photo">
                         
                        </div>
                        <div className="profile-details">
                           <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0">
                                {agent.name}
                              </h4>
                              <p>{agent.email}</p>
                           </div>

                           <div className="px-3 pt-2">
                           <h4 className="text-primary mb-0">Level {agent.agentLevel??0}</h4>
                           </div>
                          
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


         <div className="row">
            <div className="col-xl-4">
               <div className="card">
                  <div className="card-body">
                     <div className="profile-statistics mb-5">
                        <div className="text-center">
                           <div className="row">
                              <div className="col">
                                 <h3 className="m-b-0">{agent.counter}</h3>
                                 <span>Counter</span>
                              </div>
                              
                              <div className="col">
                                 <h3 className="m-b-0">{members.length}</h3>
                                 <span>Members</span>
                              </div>
                           </div>

                           <div className="row mt-4">

                           <div className="col">
                           <form onSubmit={rewardCounter}>
                           <div className="form-group">
                                        <label className="mb-2 d-flex flex-wrap align-items-center"><strong className="mr-auto">Reward Counter</strong> </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={counter}
										            placeholder="Enter Counter"
                                          required
                                            onChange={(e) =>
                                                setCounter(e.target.value)
                                            }
                                        />
                                        
                           </div>


                           <div className="text-center mt-2">
                                  {allotCounterLoading ?<Spinner animation="border" variant="primary" /> :
                                  <button
                                      type="submit"
                                      className="btn btn-primary btn-block"
                                    >
                                      Give Reward
                                    </button>}
                           </div>

                           </form>
                           </div>

                           </div>
                          
                        </div>
                       

               
                     </div>
                    
                  </div>
               </div>
            </div>
            <div className="col-xl-8">
               <div className="card">
                  <div className="card-body">
                     <div className="profile-tab">
                        <div className="custom-tab-1">
                           <ul className="nav nav-tabs">
                              <li
                                 className="nav-item"
                                 onClick={() => setActiveToggle("members")}
                              >
                                 <Link
                                    to="#members"
                                    data-toggle="tab"
                                    className={`nav-link ${
                                       activeToggle === "members"
                                          ? "active show"
                                          : ""
                                    }`}
                                 >
                                    Members
                                 </Link>
                              </li>

                              <li
                                 className="nav-item"
                                 onClick={() => setActiveToggle("subAgents")}
                              >
                                 <Link
                                    to="#subAgents"
                                    data-toggle="tab"
                                    className={`nav-link ${
                                       activeToggle === "subAgents"
                                          ? "active show"
                                          : ""
                                    }`}
                                 >
                                    Sub Agents
                                 </Link>
                              </li>

                              <li
                                 className="nav-item"
                                 onClick={() => setActiveToggle("about")}
                              >
                                 <Link
                                    to="#about"
                                    data-toggle="tab"
                                    className={`nav-link ${
                                       activeToggle === "about"
                                          ? "active show"
                                          : ""
                                    }`}
                                 >
                                    About
                                 </Link>
                              </li>
                             
                           </ul>
                           <div className="tab-content">
                              <div
                                 id="members"
                                 className={`tab-pane fade ${
                                    activeToggle === "members"
                                       ? "active show"
                                       : ""
                                 }`}
                              >
                                 <div className="my-post-content pt-3">
                                   
                                   <div className="mb-3 text-right">

                                   <CSVLink
                                    data={xlsData}
                                    filename={agent.name+"'s Members.csv"}
                                    className="btn btn-primary"
                                    target="_blank"
                                    asyncOnClick={true}
                                    onClick={async (event, done) => {
                                       console.log("You click the link");
                                       await initXlsData();
                                       done(); // 👍🏻 You are stopping the handling of component
                                    }}

                                    >
                                    Export
                                    </CSVLink>

                                   </div>

                                 <Table responsive>
                        <thead>
                           <tr>
                             
                              <th>
                                 <strong>SR NO.</strong>
                              </th>
                              <th>
                                 <strong>NAME</strong>
                              </th>
                              <th>
                                 <strong>Email</strong>
                              </th>
                              <th>
                                 <strong>Phone</strong>
                              </th>

                              <th>
                                 <strong>Counter</strong>
                              </th>

                              <th>
                                 <strong>Joined</strong>
                              </th>

                              <th>
                                 <strong>Status</strong>
                              </th>

                              <th>
                                 <strong>Action</strong>
                              </th>
                           </tr>
                        </thead>
                        <tbody>

						{
          members.map((member,i) => (

                           <tr key={i}>
                             
                              <td>
                                 <strong>{i+1}</strong>
                              </td>
                              <td>
                                 <div className="d-flex align-items-center">
                                  {""}
                                    <span className="w-space-no">
                                       {member.name}
                                    </span>
                                 </div>
                              </td>
                              <td>{member.phone} </td>
                              <td>{member.email} </td>
                              <td>{member.counter} </td>
                           
                              <td>{ format(new Date(member.createdAt), "dd/MM/yyyy H:mma")}</td>

                             
                              <td>

{member.status === "active"? (

      <Badge variant="success light">
            Activated
            <span className="ml-1 fa fa-check"></span>
      </Badge>)
      
:member.status === "deactive" ? (
         <Badge variant="danger light">
            Deactivated
            <span className="ml-1 fa fa-ban"></span>
         </Badge>
      ):(<></>)
}


</td>

                              <Dropdown>
                                  <Dropdown.Toggle
                                     variant=""
                                     className="table-dropdown icon-false"
                                  >
                                     <svg
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                     >
                                        <g
                                           stroke="none"
                                           strokeWidth="1"
                                           fill="none"
                                           fillRule="evenodd"
                                        >
                                           <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                           ></rect>
                                           <circle
                                              fill="#000000"
                                              cx="5"
                                              cy="12"
                                              r="2"
                                           ></circle>
                                           <circle
                                              fill="#000000"
                                              cx="12"
                                              cy="12"
                                              r="2"
                                           ></circle>
                                           <circle
                                              fill="#000000"
                                              cx="19"
                                              cy="12"
                                              r="2"
                                           ></circle>
                                        </g>
                                     </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                     <Dropdown.Item as="button" onClick={(e)=>changeUserStatus(member.id,member.status === "active" ? "deactive":"active")}>
                                          {member.status === "active" ? "Deactive Now":"Active Now"}
                                     </Dropdown.Item>
                                    
                                    {/*  <div className="dropdown-divider"></div>
                                     <Dropdown.Item
                                        href="#"
                                        className="text-danger"
                                     >
                                        Delete
                                     </Dropdown.Item> */}
                                  </Dropdown.Menu>
                               </Dropdown>
                             
                           </tr>))
						   
						}

                         
                        </tbody>
                     </Table>

                                 </div>
                              </div>
                              
                              <div
                                 id="subAgents"
                                 className={`tab-pane fade ${
                                    activeToggle === "subAgents"
                                       ? "active show"
                                       : ""
                                 }`}
                              >
                                 <div className="my-post-content pt-3">
                                   
                                   <div className="mb-3 text-right">

                                   <CSVLink
                                    data={xlsData}
                                    filename={agent.name+"'s SubAgents.csv"}
                                    className="btn btn-primary"
                                    target="_blank"
                                    asyncOnClick={true}
                                    onClick={async (event, done) => {
                                       console.log("You click the link");
                                       await initXlsData();
                                       done(); // 👍🏻 You are stopping the handling of component
                                    }}

                                    >
                                    Export
                                    </CSVLink>

                                   </div>

                                 <Table responsive>
                        <thead>
                           <tr>
                             
                              <th>
                                 <strong>SR NO.</strong>
                              </th>
                              <th>
                                 <strong>NAME</strong>
                              </th>
                              <th>
                                 <strong>Phone</strong>
                              </th>

                              <th>
                                 <strong>Counter</strong>
                              </th>

                              <th>
                                 <strong>Joined</strong>
                              </th>

                              <th>
                                 <strong>Status</strong>
                              </th>

                              <th>
                                 <strong>Action</strong>
                              </th>
                           </tr>
                        </thead>
                        <tbody>

						{
          agents.map((agent,i) => (

                           <tr key={i}>
                             
                              <td><Link to={"/agent/"+agent.id}>#{agent.id}</Link></td>
                              <td>{agent.name}<br/><small>{agent.email}</small></td>
                              <td>{agent.phone} </td>
                              <td><strong>{agent.counter}</strong> </td>
                           
                              <td>{ format(new Date(agent.createdAt), "dd/MM/yyyy H:mma")}</td>

                             
                              <td>

{agent.status === "active"? (

      <Badge variant="success light">
            Activated
            <span className="ml-1 fa fa-check"></span>
      </Badge>)
      
:agent.status === "deactive" ? (
         <Badge variant="danger light">
            Deactivated
            <span className="ml-1 fa fa-ban"></span>
         </Badge>
      ):(<></>)
}


</td>

                              <Dropdown>
                                  <Dropdown.Toggle
                                     variant=""
                                     className="table-dropdown icon-false"
                                  >
                                     <svg
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                     >
                                        <g
                                           stroke="none"
                                           strokeWidth="1"
                                           fill="none"
                                           fillRule="evenodd"
                                        >
                                           <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                           ></rect>
                                           <circle
                                              fill="#000000"
                                              cx="5"
                                              cy="12"
                                              r="2"
                                           ></circle>
                                           <circle
                                              fill="#000000"
                                              cx="12"
                                              cy="12"
                                              r="2"
                                           ></circle>
                                           <circle
                                              fill="#000000"
                                              cx="19"
                                              cy="12"
                                              r="2"
                                           ></circle>
                                        </g>
                                     </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                     <Dropdown.Item as="button" onClick={(e)=>changeAgentStatus(agent.id,agent.status === "active" ? "deactive":"active")}>
                                          {agent.status === "active" ? "Deactive Now":"Active Now"}
                                     </Dropdown.Item>
                                    
                                  
                                  </Dropdown.Menu>
                               </Dropdown>
                             
                           </tr>))
						   
						}

                         
                        </tbody>
                     </Table>

                                 </div>
                              </div>
                              
                              <div
                                 id="about"
                                 className={`tab-pane fade ${
                                    activeToggle === "about"
                                       ? "active show"
                                       : ""
                                 }`}
                              >


                                 <div className="row mt-4">

                                 <div className="col mb-4">
							<h6>Address:</h6>
							<div> <strong>{agent.address.addressLine1} {agent.address.addressLine2}</strong> </div>
							<div>{agent.address.lanmark}</div>
                     <div>{agent.address.city}</div>
							<div>{agent.address.state}</div>
							<div>{agent.address.country} - {agent.address.zip}</div>
						</div>

                                 </div>


                                
                              </div>
                              <div
                                 id="profile-settings"
                                 className={`tab-pane fade ${
                                    activeToggle === "setting"
                                       ? "active show"
                                       : ""
                                 }`}
                              >
                                 <div className="pt-3">
                                   
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


               </>
           )}
         </div>
         </div>
      </Fragment>
   );
};

export default AgentDetails;
