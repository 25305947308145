import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Dropdown, Button, Modal } from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { SRLWrapper } from "simple-react-lightbox";

import { useDispatch, useSelector } from "react-redux";

import axiosInstance from "../../services/AxiosInstance";

import swal from "sweetalert";

import FormData from 'form-data';

const Setting = () => {
  const [activeToggle, setActiveToggle] = useState("posts");
  const [sendMessage, setSendMessage] = useState(false);
  const [replay, setReplay] = useState(false);

  const dispatch = useDispatch();

  let errorsObj = {
    name: "",
    description: "",
    androidUpdate: "",
    iosUpdate: "",
    androidVersion: "",
    iosVersion: "",
    appStoreUrl: "",
    playStoreUrl: "",
    newsUpdate: "",
    frontImage: "",
    frontLink: "",
    backImage: "",
    backLink: ""
  };
  const [errors, setErrors] = useState(errorsObj);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [androidUpdate, setAndroidUpdate] = useState(true);
  const [androidVersion, setAndroidVersion] = useState("");
  const [playStoreUrl, setPlayStoreUrl] = useState("");
  const [newsUpdate, setNewsUpdate] = useState("");
  const [frontImage, setFrontImage] = useState("");
  const [frontLink, setFrontLink] = useState("");
  const [backImage, setBackImage] = useState("");
  const [backLink, setBackLink] = useState("");

  //const [profile, setProfile] = useState({});

  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

  useEffect(() => {
    loadDetails();
  }, [dispatch]);

  const loadDetails = async () => {
    const { data } = await axiosInstance.get("admin/app-data");

    if (data.data) {
     
      setName(data.data.name ?? "");
      setDescription(data.data.description ?? "");
      setAndroidUpdate(data.data.androidUpdate);
      setAndroidVersion(data.data.androidVersion);
      setPlayStoreUrl(data.data.playStoreUrl);
      setNewsUpdate(data.data.newsUpdate);
   
      setFrontImage(data.data.frontBanner.image);
      setFrontLink(data.data.frontBanner.link);
      setBackImage(data.data.backBanner.image);
      setBackLink(data.data.backBanner.link);
     
    }

    console.log("details data - " + JSON.stringify(data));
  };

  const onSaveProfile = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (name === "") {
      errorObj.name = "App Name is Required";
      error = true;
    }

    if (description === "") {
      errorObj.type = "Description is Required";
      error = true;
    }

    if (androidVersion === "") {
      errorObj.androidVersion = "Android Version is Required";
      error = true;
    }
    
    setErrors(errorObj);
    if (error) {
      console.log("error on save");
      return;
    }
    console.log("no error on save");
    //save data

    const formData = {
      name: name,
      description: description,
      android_update: androidUpdate,
      android_version: androidVersion,
      playstore_url: playStoreUrl,
      news_update: newsUpdate,
      front_banner_image: frontImage,
      front_banner_link: frontLink,
      back_banner_image: backImage,
      back_banner_link: backLink
    };

    console.log("form data - " + formData);

    const { data } = await axiosInstance.post(
      "admin/save-app-data",
      formData
    );

    if (data.status) {
      swal("Update App Data", data.message, "success");
    } else {
      swal("Update App Data", data.message, "error");
    }
  };




  const fileChangedHandler = async (event) => {
   var fileInput = false;
   if (event.target.files[0]) {
     fileInput = true;
   }
   if (fileInput) {
     try {
      //  Resizer.imageFileResizer(
      //    event.target.files[0],
      //    300,
      //    300,
      //    "PNG",
      //    100,
      //    0,
      //    (uri) => {
      //      console.log(uri);
      //      //this.setState({ newImage: uri });
      //      setProfileImage(uri);
      //    },
      //    "base64",
      //    200,
      //    200
      //  );

      //upload image

      var file = event.target.files[0];

      const config = {
         headers: {
      
          'Content-Type': 'multipart/form-data'
      
         }
      }

      const formData = new FormData();

      formData.append('image', file, file.name);
      formData.append("type","banner")

      const { data } = await axiosInstance.post(
         "admin/upload-image",
         formData
       );

       //console.log("image upload response - "+data);
       console.log("image upload response - "+JSON.stringify(data));

       if(data.status){
         swal("Image Upload", data.message, "success");
         setFrontImage(data.downloadUrl);
       }else{
         swal("Image Upload", data.message, "error");
       }


     } catch (err) {
       console.log(err);
       //setProfileImage("");
     }
   }
 }

 
 const fileClickHandler = event => {
   const { target = {} } = event || {};
   target.value = "";
 }

 const upload = (e) => {
   e.preventDefault();
   document.getElementById("selectFile").click();
 }



 
 const fileChangedHandler2 = async (event) => {
   var fileInput = false;
   if (event.target.files[0]) {
     fileInput = true;
   }
   if (fileInput) {
     try {
      var file = event.target.files[0];

      const config = {
         headers: {
      
          'Content-Type': 'multipart/form-data'
      
         }
      }

      const formData = new FormData();

      formData.append('image', file, file.name);
      formData.append("type","banner")

      const { data } = await axiosInstance.post(
         "admin/upload-image",
         formData
       );

       //console.log("image upload response - "+data);
       console.log("image upload response - "+JSON.stringify(data));

       if(data.status){
         swal("Image Upload", data.message, "success");
         setBackImage(data.downloadUrl);
       }else{
         swal("Image Upload", data.message, "error");
       }
     } catch (err) {
       console.log(err);
       //setProfileImage("");
     }
   }
 }

 
 const fileClickHandler2 = event => {
   const { target = {} } = event || {};
   target.value = "";
 }

 const upload2 = (e) => {
   e.preventDefault();
   document.getElementById("selectFile2").click();
 }



  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div id="">
                <div className="pt-3">
                  <div className="settings-form">
                    <h4 className="text-primary">App Info</h4>
                    <form onSubmit={onSaveProfile}>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Name</label>
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            className="form-control"
                          />
                          {errors.name && (
                            <div className="text-danger fs-12">
                              {errors.name}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label>Description</label>
                          <input
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Description"
                            className="form-control"
                          />
                          {errors.description && (
                            <div className="text-danger fs-12">
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-row">
                      <div className="form-group col-md-3">
                        <label>Android Force Update</label>
                        {/* <input
                          type="text"
                          value={androidUpdate}
                          onChange={(e) => setAndroidUpdate(e.target.value)}
                          placeholder="Android Update"
                          className="form-control"
                        /> */}

<select
                          className="form-control"
                          value={androidUpdate ? "Yes":"No"}
                          onChange={(e) => setAndroidUpdate(e.target.value == "Yes")}
                          defaultValue="Yes"
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>

                        {errors.androidUpdate && (
                          <div className="text-danger fs-12">{errors.androidUpdate}</div>
                        )}
                      </div>

                      <div className="form-group col-md-3">
                          <label>Android Version</label>
                          <input
                            type="number"
                            value={androidVersion}
                            onChange={(e) => setAndroidVersion(e.target.value)}
                            placeholder="Android Version"
                            className="form-control"
                          />
                          {errors.androidVersion && (
                            <div className="text-danger fs-12">
                              {errors.androidVersion}
                            </div>
                          )}
                        </div>

                      <div className="form-group col-md-6">
                          <label>Playstore Url</label>
                          <input
                            type="text"
                            value={playStoreUrl}
                            onChange={(e) => setPlayStoreUrl(e.target.value)}
                            placeholder="Playstore Url"
                            className="form-control"
                          />
                          {errors.playStoreUrl && (
                            <div className="text-danger fs-12">
                              {errors.playStoreUrl}
                            </div>
                          )}
                        </div>

                      </div>

                      <div className="form-row">
                        

                        <div className="form-group col-md-12">
                          <label>News Update</label>
                        

<textarea 
                                rows="5"
                                type="text"
                                value={newsUpdate}
                                className="form-control"
                                autocomplete="off"
                              
                                required="required"
                                onChange={(e) => setNewsUpdate(e.target.value)}
                                placeholder="News Update"
                              />

                          {errors.newsUpdate && (
                            <div className="text-danger fs-12">
                              {errors.newsUpdate}
                            </div>
                          )}
                        </div>
                      </div>

                     
                      <div className="form-row">
                        

                           <div className="form-group col-md-3">

                             <label>Front Banner</label>

                              <div className="text-center">
                                 <img onClick={upload} className="img img-fluid my-4" style={{"width":"200px","height":"80px"}} src={frontImage != ""?frontImage:"https://pvc2print.s3.ap-south-1.amazonaws.com/logo.png"}></img>
                                 <input id="selectFile" type="file" hidden onChange={fileChangedHandler}
                                 onClick={fileClickHandler} />
                              </div>

                           </div>

                     <div className="form-group col-md-9">

                     <input
                            type="text"
                            value={frontImage}
                            onChange={(e) => setFrontImage(e.target.value)}
                            placeholder="Image Url"
                            className="form-control"
                          />

                     <input
                            type="text"
                            value={frontLink}
                            onChange={(e) => setFrontLink(e.target.value)}
                            placeholder="Link"
                            className="form-control"
                          />

                     </div>

                     </div>


                     <div className="form-row">
                        

                        <div className="form-group col-md-3">

                          <label>Back Banner</label>

                           <div className="text-center">
                              <img onClick={upload2} className="img img-fluid my-4" style={{"width":"200px","height":"80px"}} src={backImage != ""?backImage:"https://pvc2print.s3.ap-south-1.amazonaws.com/logo.png"}></img>
                              <input id="selectFile2" type="file" hidden onChange={fileChangedHandler2}
                              onClick={fileClickHandler2} />
                           </div>

                        </div>

                  <div className="form-group col-md-9">

                  <input
                         type="text"
                         value={backImage}
                         onChange={(e) => setBackImage(e.target.value)}
                         placeholder="Image Url"
                         className="form-control"
                       />

                  <input
                         type="text"
                         value={backLink}
                         onChange={(e) => setBackLink(e.target.value)}
                         placeholder="Link"
                         className="form-control"
                       />

                  </div>

                  </div>

                      <button className="btn btn-primary" type="submit">
                        Save
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Setting;
