import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import menu03 from "../../../images/menus/3.png";
import menu02 from "../../../images/menus/2.png";
import menu01 from "../../../images/menus/1.png";

import testimonial from "../../../images/testimonial/1.jpg";
import testimonial2 from "../../../images/testimonial/2.jpg";
import testimonial3 from "../../../images/testimonial/3.jpg";

import { useDispatch, useSelector } from "react-redux";

import axiosInstance from "../../../services/AxiosInstance";
import {
  Row,
  Card,
  Col,
  ListGroup,
  Badge,
  Tab,
  Dropdown,
  Nav,
  Spinner
} from "react-bootstrap";


import ReactApexChart from "react-apexcharts";

import { getProfile } from "../../../store/actions/AuthActions";

// import Ext from "../../layouts/Ext";

const Home = () => {
  const dispatch = useDispatch();

  const [statistics, setStatistics] = useState(null);
  const [filter, setFilter] = useState("daily");
  const [type, setType] = useState("all");
  const [loadingData, setLoadingData] = useState(true);

  const [totalCounter, setTotalCounter] = useState(0);

  const [options, setOptions] = useState({});
	const [series, setSeries] = useState({});

  useEffect(() => {
    dispatch(getProfile());
    loadStatistics();
    loadCounterStatistics(filter,type);
  }, [dispatch]);

  async function loadStatistics() {
    try {
      const { data } = await axiosInstance.get("admin/statistics");

      console.log("statistics data - " + JSON.stringify(data));

      if (data.status === false) {
      } else {
        setStatistics(data);
      }
    } catch (error) {}
  }

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="owl-next" onClick={onClick} style={{ zIndex: 99 }}>
        <i className="fa fa-caret-right" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="owl-prev disabled"
        onClick={onClick}
        style={{ zIndex: 99 }}
      >
        <i className="fa fa-caret-left" />
      </div>
    );
  }

  async function onChangeFilter(filter) {
    setFilter(filter);
    await loadCounterStatistics(filter,type);
  }

  async function onChangeType(type) {
  
   setType(filter);
   await loadCounterStatistics(filter,type);
 }

  async function loadCounterStatistics(filter,type) {
   setLoadingData(true);
    try {
      const { data } = await axiosInstance.post("admin/counter-statistics", {
        type: type,
        filter: filter,
      });

      console.log("filter data - " + JSON.stringify(data));

      if (data.status) {
        //setTodaysTaps(data.todayResult);

        var tc = 0;
        data.result.map((e) => {
          tc = tc + e.total;
        });

        setTotalCounter(tc);

        setSeries([
          {
            name: "Counter",
            data: data.result.map((e) => e.total),
          },
        ]);

        setOptions({
          chart: {
            height: 350,
            type: "line",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },

          stroke: {
            width: [4, 4],
            colors: ["#6cc51d"],
            curve: "straight",
          },
          legend: {
            show: false,
          },
          xaxis: {
            type: "text",
            categories: data.result.map((e) => e._id),
          },
          colors: ["#6cc51d", "#1EA7C5", "#FF9432"],

          markers: {
            size: [8, 8, 6],
            strokeWidth: [0, 0, 4],
            strokeColors: ["#6cc51d", "#1EA7C5", "#FF9432"],
            border: 0,
            colors: ["#6cc51d", "#1EA7C5", "#fff"],
            hover: {
              size: 10,
            },
          },
          yaxis: {
            title: {
              text: "",
            },
          },
        });

        
      } 
      
      setLoadingData(false);
      
    } catch (error) {
      setLoadingData(false);
    }
  }

  const settings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return statistics ? (
    <>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="card bordered-card">
            <div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics ? statistics.users : 0}
                </h2>
                <span className="circle-1 bg-primary mt-4" />
              </div>

              <span className="fs-14">Users</span>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6">
          <div className="card bordered-card">
            <div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics ? statistics.agents : 0}
                </h2>
                <span className="circle-1 bg-success mt-4" />
              </div>

              <span className="fs-14">Agents</span>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-4 col-sm-6">
          <div className="card bordered-card">
            <div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics ? statistics.orders : 0}
                </h2>
                <span className="circle-1 bg-warning mt-4" />
              </div>

              <span className="fs-14">Oders</span>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-4 col-sm-6">
          <div className="card bordered-card">
            <div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics ? statistics.counterUsed : 0}
                </h2>
                <span className="circle-1 bg-danger mt-4" />
              </div>

              <span className="fs-14">Counter Used</span>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card bordered-card">
            <div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics ? statistics.newUsersToday : 0}
                </h2>
                <span className="circle-1 bg-primary mt-4" />
              </div>

              <span className="fs-14">New Users</span>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card bordered-card">
            <div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics ? statistics.counterUsedToday : 0}
                </h2>
                <span className="circle-1 bg-primary mt-4" />
              </div>

              <span className="fs-14">Used Counters Today</span>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card bordered-card">
            <div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics ? statistics.counterUsedYesterday : 0}
                </h2>
                <span className="circle-1 bg-primary mt-4" />
              </div>

              <span className="fs-14">Used Counters Yesterday</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header flex-wrap pb-0 border-0">
              <div className="mr-auto pr-3 mb-2">
                <h4 className="text-black fs-20">Counter Statistic</h4>
                <p className="fs-13 mb-2 mb-sm-0 text-black">
                  Total Counter Used
                </p>
              </div>
              <div className="d-flex mr-3 mr-sm-5 mb-2">
                <div>
                  <h4 className="fs-18 text-black font-w600 mb-0">
                    {totalCounter}
                  </h4>
                  <span className="fs-12 text-black">Counter</span>
                </div>
              </div>
              

              <Dropdown className="dropdown mt-sm-0 mt-3 mb-0">
                <Dropdown.Toggle
                  variant=""
                  as="button"
                  className="btn rounded border border-light dropdown-toggle"
                >
                  {filter}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-right">
                  <Dropdown.Item onClick={(e) => onChangeFilter("daily")}>
                    Daily
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => onChangeFilter("monthly")}>
                    Monthly
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => onChangeFilter("yearly")}>
                    Yearly
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="card-body pt-3">
              {loadingData ? (
                <div className="text-center my-2">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <div id="chart">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="line"
                    height={380}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>


      <div className="row">
        <Col xl="4">
          <Card>
            <Card.Header>
              <Card.Title>Cards</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="basic-list-group">
                <ListGroup>
                  {statistics.cardType.map((card, i) => (
                    <ListGroup.Item
                      className="d-flex justify-content-between align-items-center"
                      key={i}
                    >
                      {card._id
                        ? card._id != ""
                          ? card._id
                          : "Unknown"
                        : "Unknown"}
                      <Badge variant="primary text-white" pill>
                        {card.count}
                      </Badge>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xl="4">
          <Card>
            <Card.Header>
              <Card.Title>Printers</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="basic-list-group">
                <ListGroup>
                  {statistics.printers.map((printer, i) => (
                    <ListGroup.Item
                      className="d-flex justify-content-between align-items-center"
                      key={i}
                    >
                      {printer._id
                        ? printer._id != ""
                          ? printer._id
                          : "Unknown"
                        : "Unknown"}
                      <Badge variant="primary text-white" pill>
                        {printer.count}
                      </Badge>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>

     
    </>
  ) : (
    <></>
  );
};

export default Home;
